import { FeatureEnum } from "../enums/FeatureEnum";
import { PlanEnum } from "../../../../modules/stripe/enums/PlanEnum";

export const featureUtil = {
  notAvailableText: "Not available in your current plan",
  notAvailableFullText:
    "Forbidden. This feature is not available in your current subscription plan. Please upgrade your plan from your billing settings to access this feature.",
  starterFeatures: () => [
    FeatureEnum.AUTO_SPLIT,
    FeatureEnum.AUTO_ADJUST,
    FeatureEnum.HOLISTIC_VIEW,
    FeatureEnum.INITIATIVE_BOARD,
    FeatureEnum.TEAMS_MANAGEMENT,
    FeatureEnum.STATUS_FLOW,
    FeatureEnum.TASK_TYPE,
    FeatureEnum.TASK_PRIORITY,
    FeatureEnum.TASK_LINKS,
    FeatureEnum.PRIORITY_VIEW,
    FeatureEnum.KANBAN_VIEW,
    FeatureEnum.LIST_VIEW,
    FeatureEnum.INTEGRATIONS,
    FeatureEnum.OCCUPIED_SPACE,
    FeatureEnum.SPRINT_CONTROLS,
    FeatureEnum.MOBILE_APP,
    FeatureEnum.WORKSPACE_CONTROL,
    FeatureEnum.TASK_DELETE,
    FeatureEnum.INITIATIVE_NOTES,
    FeatureEnum.VIRTUAL_USERS, // +
    FeatureEnum.PROJECTS,
    FeatureEnum.LIVE_SUPPORT,
    FeatureEnum.HOT_KEYS,
  ],
  basicFeatures: () => [
    ...featureUtil.starterFeatures(),
    FeatureEnum.OBJECTIVES, // +
    FeatureEnum.HOLISTIC_SHIFT, // +
    FeatureEnum.HOLISTIC_RESIZE, // +
    FeatureEnum.MULTI_ASSIGN, // +
    FeatureEnum.MANUAL_SPLIT, // +
    FeatureEnum.OFFTIME_MANAGEMENT, // +
    FeatureEnum.NON_WORKDAY_CONTROL, // +
    FeatureEnum.CUSTOM_FIELDS, // +
    FeatureEnum.TASK_DEFAULT_VALUES, // +
    FeatureEnum.BACKLOG_ICEBOX, // +
    FeatureEnum.SHARING_BOARDS,
    FeatureEnum.ADVANCED_ACCESS_CONTROL,
    FeatureEnum.MONTHLY_WEEKLY_REPORTS_INVOICES,
    FeatureEnum.FIBONACCI_CONTROL,
    FeatureEnum.PUBLIC_API,
    FeatureEnum.PROJECT_COLOR_CODING,
  ],
  premiumFeatures: () => [
    ...featureUtil.starterFeatures(),
    ...featureUtil.basicFeatures(),
    FeatureEnum.AI_SENTINEL, // +
    FeatureEnum.FINANCIAL_FORECAST, // +
    FeatureEnum.EVENT,
    FeatureEnum.DASHBOARDS,
    FeatureEnum.GUESSTIMATION_BOARD,
    FeatureEnum.MONITORS,
    FeatureEnum.TASK_STATES,
    FeatureEnum.SPIKE,
    FeatureEnum.CAPACITY_AND_VELOCITY,
    FeatureEnum.WORKING_HOURS_CONTROL,
    FeatureEnum.RECURRING_TASKS,
    FeatureEnum.BATCH_CONTROLS,
    FeatureEnum.PERFORMANCE_REPORTS,
    FeatureEnum.FREE_SETUP,
    FeatureEnum.TEAM_RESOURCE_AVAILABILITY,
  ],
  businessFeatures: () => [
    ...featureUtil.starterFeatures(),
    ...featureUtil.basicFeatures(),
    ...featureUtil.premiumFeatures(),
    FeatureEnum.WORKFLOW_AUTOMATION,
  ],
  getPlanFeatures: (plan: PlanEnum) => {
    switch (plan) {
      case PlanEnum.STARTER:
        return featureUtil.starterFeatures();
      case PlanEnum.BASIC:
        return featureUtil.basicFeatures();
      case PlanEnum.PREMIUM:
        return featureUtil.premiumFeatures();
      case PlanEnum.BUSINESS:
        return featureUtil.businessFeatures();
    }
  },
  planHasFeature: (plan: PlanEnum, feature: FeatureEnum) => {
    return featureUtil.getPlanFeatures(plan).includes(feature);
  },
};
