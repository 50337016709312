import classes from "./LaneItemHorizontal.module.scss";
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react";
import { TaskHelper } from "../../../../../../common/components/Task/helpers/TaskHelper";
import StateIndicator from "../../../../../../common/components/Task/components/State/StateIndicator";
import StateIcon from "../../../../../../common/components/Task/components/State/StateIcon";
import Resizer from "../../../../../../common/components/Task/components/Resizer/Resizer";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { guesstimationItemViewActions } from "../../../../../guesstimation/modules/view/slices/viewSlice";
import { TChildren } from "../../../../../../common/types/TChildren";
import { THorizontalItemProps } from "./types/THorizontalItemProps";

type TProps = {
  contentClassName?: string;
  contentStyle?: React.CSSProperties;
} & THorizontalItemProps &
  TChildren;

const LaneItemHorizontal = forwardRef(
  (props: TProps, ref: ForwardedRef<HTMLDivElement>) => {
    const dispatch = useAppDispatch();
    const [parentNode, setParentNode] = useState<HTMLDivElement | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const openItemViewHandler = () => {
      dispatch(
        guesstimationItemViewActions.open({ ref: props.guesstimationItem.ref })
      );
    };
    const sizeXs = TaskHelper.sizeXs(props.size!);

    useEffect(() => {
      setParentNode(containerRef.current);
    }, [containerRef.current]);

    return (
      <div
        ref={containerRef}
        className={`${props.className} ${classes.container ?? ""}`}
        style={{
          ...{
            top: props.positionY ? props.positionY : undefined,
            left: props.positionX,
            width: props.size,
          },
          ...props.style,
        }}
        onDragStart={props.onDragStart}
      >
        <div
          onClick={openItemViewHandler}
          ref={ref}
          className={`${props.contentClassName} ${classes.content ?? ""}`}
          style={props.contentStyle}
        >
          {!sizeXs && (
            <StateIndicator
              className={classes.state}
              statusType={props.guesstimationItem.statusType}
              status={props.guesstimationItem.status}
            />
          )}
          <StateIcon
            className={
              !sizeXs ? classes["state-icon"] : classes["state-icon-xs"]
            }
            statusType={props.guesstimationItem.statusType}
            status={props.guesstimationItem.status}
          />
          <div>{props.children}</div>
        </div>
        {props.resizable && (
          <Resizer parentNode={parentNode} {...props.resizerConfig} />
        )}
      </div>
    );
  }
);

export default LaneItemHorizontal;
