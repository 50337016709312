import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { XYCoord } from "dnd-core";
import { IUser } from "../../../../../entities/IUser";
import { IGuesstimationItem } from "../../../../../entities/IGuesstimationItem";

type TInitialState = {
  isDragging?: boolean;
  draggingItemLaneIndex?: number;
  showShiftingProgress?: boolean;
  dragStartCoords?: XYCoord;
  dropData?: {
    itemLaneIndex: number;
    coords: XYCoord;
    guesstimationItem: IGuesstimationItem;
    users: IUser[];
  };
  dragSummary?: string;
  dropContainerData: {
    currentDateStartXCoord?: number;
    currentXCoord?: number;
    currentHours?: number;
    currentDate?: string;
    dragPosition?: XYCoord | null;
  };
};

const initialState: TInitialState = {
  dropContainerData: {},
};

const slice = createSlice({
  name: "guesstimationViewItem",
  initialState,
  reducers: {
    setIsDragging: (
      state,
      action: PayloadAction<{
        isDragging: boolean;
        draggingItemLaneIndex?: number;
      }>
    ) => {
      state.isDragging = action.payload.isDragging;
      state.draggingItemLaneIndex = action.payload.draggingItemLaneIndex;
    },
    showShiftingProgress: (state, action: PayloadAction<boolean>) => {
      state.showShiftingProgress = action.payload;
    },
    setDragStartCoords: (
      state,
      action: PayloadAction<TInitialState["dragStartCoords"]>
    ) => {
      state.dragStartCoords = action.payload;
    },
    setDropData: (state, action: PayloadAction<TInitialState["dropData"]>) => {
      state.dropData = action.payload;
    },
    updateDragSummary: (state, action: PayloadAction<string>) => {
      state.dragSummary = action.payload;
    },
    setDropContainerCoords: (state, action: PayloadAction<XYCoord>) => {
      state.dropContainerData.dragPosition = action.payload;
    },
    setDropContainerData: (
      state,
      action: PayloadAction<TInitialState["dropContainerData"]>
    ) => {
      state.dropContainerData.currentDateStartXCoord =
        action.payload.currentDateStartXCoord;
      state.dropContainerData.currentXCoord = action.payload.currentXCoord;
      state.dropContainerData.currentHours = action.payload.currentHours;
      state.dropContainerData.currentDate = action.payload.currentDate;
    },
  },
});

export const guesstimationViewItemReducer = slice.reducer;

export const guesstimationViewItemActions = slice.actions;
