import { TCreateBacklogItemFormProps } from "./types/TCreateBacklogItemFormProps";
import React, { useEffect } from "react";
import { Button, TextField } from "../../../../../../common/modules/materialUI";
import Form from "../../../../../../common/components/Form/Form";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import { TCreateBacklogItemFormAttributes } from "./types/TCreateBacklogItemFormAttributes";
import useMedia from "../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../common/hooks/useMedia/enums/QueryEnum";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import useTaskType from "../../../../../../common/modules/task/hooks/useTaskType";
import useTaskPriority from "../../../../../../common/modules/task/hooks/useTaskPriority";
import { FormControl, FormHelperText, Grid } from "@mui/material";
import TaskTypeAutocomplete from "../../../../../taskType/containers/TaskTypeAutocomplete/TaskTypeAutocomplete";
import TaskPriorityAutocomplete from "../../../../../taskPriority/containers/TaskPriorityAutocomplete/TaskPriorityAutocomplete";
import { useFetchCustomFieldsByBoardRefService } from "../../../../../customField/services/useFetchCustomFieldsByBoardRefService";
import CustomFieldInput from "../../../../../customField/containers/CustomFieldInput/CustomFieldInput";
import { customFieldInputUtil } from "../../../../../customField/utils/customFieldInputUtil";
import { useCreateBacklogItemService } from "../../services/useCreateBacklogItemService";
import { TCreateBacklogItemFormAttributesTransformed } from "./types/TCreateBacklogItemFormAttributesTransformed";
import { IBacklogItem } from "../../../../../../entities/IBacklogItem";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { TBacklogItemCreatedEvent } from "./types/TBacklogItemCreatedEvent";
import { useFetchEntityFieldDefaultValuesByBoardRefService } from "../../../../../task/services/useFetchEntityFieldDefaultValuesByBoardRefService";
import { entityFieldDefaultValueUtil } from "../../../../../task/utils/entityFieldDefaultValueUtil";
import { backlogItemUtil } from "../../../../../../common/utils/backlogItemUtil";

const CreateBacklogItemForm = (props: TCreateBacklogItemFormProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const { taskTypeAutocompleteData } = useTaskType();
  const { taskPriorityAutocompleteData } = useTaskPriority();
  const form = useForm<TCreateBacklogItemFormAttributes>({
    boardRef: props.boardRef,
  });
  const { isLoading, dispatch: dispatchCreateBacklogItem } =
    useCreateBacklogItemService();
  const {
    isLoading: isCustomFieldsLoading,
    dispatch: dispatchFetchCustomFields,
    data: customFieldsResource,
  } = useFetchCustomFieldsByBoardRefService({ boardRef: props.boardRef });
  const {
    isLoading: isEntityFieldDefaultValuesLoading,
    dispatch: dispatchFetchEntityFieldDefaultValues,
    data: entityFieldDefaultValuesResource,
  } = useFetchEntityFieldDefaultValuesByBoardRefService();
  const {
    taskTypes,
    taskTypeOptions,
    isLoading: isTaskTypesLoading,
  } = taskTypeAutocompleteData(props.boardRef);
  const {
    taskPriorities,
    taskPriorityOptions,
    isLoading: isTaskPrioritiesLoading,
  } = taskPriorityAutocompleteData(props.boardRef);

  const isDefaultDataLoading =
    isCustomFieldsLoading ||
    isEntityFieldDefaultValuesLoading ||
    isTaskTypesLoading ||
    isTaskPrioritiesLoading;

  useEffect(() => {
    dispatchFetchCustomFields();
    dispatchFetchEntityFieldDefaultValues({ urlPath: props.boardRef });
  }, []);

  useEffect(() => {
    if (isDefaultDataLoading || !entityFieldDefaultValuesResource) {
      return;
    }

    form.changeDefaultData({
      boardRef: props.boardRef,
      ...entityFieldDefaultValueUtil.getFormDefaultValues(
        entityFieldDefaultValuesResource,
        taskTypeOptions,
        taskPriorityOptions,
        customFieldsResource?.customFields
      ),
    });
  }, [isDefaultDataLoading]);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchCreateBacklogItem({
      body: form.getTransformed<TCreateBacklogItemFormAttributesTransformed>(
        (data) => {
          return {
            ...data,
            taskTypeId: data.taskTypeId?.id,
            taskPriorityId: data.taskPriorityId?.id,
            ...customFieldInputUtil.getCustomFieldFormAttributesTransformed(
              customFieldsResource?.customFields ?? [],
              data
            ),
          };
        }
      ),
    })
      .then((payload: IBacklogItem) => {
        dispatchEvent(EventEnum.ON_BACKLOG_ITEM_CREATED, {
          backlogItem: payload,
        } as TBacklogItemCreatedEvent);
        dispatch(
          systemNotificationActions.open({
            message: {
              type: "withLinks",
              text: "{link} backlog item has been created successfully",
              placeholders: {
                link: {
                  url: backlogItemUtil.urlByRef(payload.ref),
                  label: payload.ref,
                },
              },
            },
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
          return;
        }

        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading || isDefaultDataLoading || !form.isDirty()}
            variant="contained"
            type="submit"
            loading={isLoading || isDefaultDataLoading}
          >
            Add
          </Button>
        </>
      }
    >
      <TextField
        forceFocus={!isTouchDevice}
        form={form}
        name="name"
        label="Summary"
        showRequired
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TaskTypeAutocomplete
            form={form}
            name="taskTypeId"
            loading={isTaskTypesLoading}
            options={taskTypeOptions}
            taskTypes={taskTypes}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TaskPriorityAutocomplete
            form={form}
            name="taskPriorityId"
            loading={isTaskPrioritiesLoading}
            options={taskPriorityOptions}
            taskPriorities={taskPriorities}
          />
        </Grid>
      </Grid>

      {customFieldsResource?.customFields &&
        customFieldsResource?.customFields.map((customField) => (
          <CustomFieldInput
            key={customField.id}
            customField={customField}
            form={form}
            hideRequiredMark
            showLabel
          />
        ))}
      {form.hasError(
        customFieldInputUtil.customFieldsAttribute as keyof TCreateBacklogItemFormAttributes
      ) && (
        <FormControl error>
          <FormHelperText>
            {form.getError(
              customFieldInputUtil.customFieldsAttribute as keyof TCreateBacklogItemFormAttributes
            )}
          </FormHelperText>
        </FormControl>
      )}
    </Form>
  );
};

export default CreateBacklogItemForm;
