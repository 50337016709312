import { TBoardSettingsSectionProps } from "./types/TBoardSettingsSectionProps";
import List from "../../common/List/List";
import { DriveFileRenameOutline, Settings } from "@mui/icons-material";
import ArchiveBoard from "../../../../../../../modules/board/containers/Archive/ArchiveBoard";
import { useIsFeatureAllowed } from "../../../../../../modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../../../modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../../../../modules/accessControl/utils/featureUtil";

const BoardSettingsSection = (props: TBoardSettingsSectionProps) => {
  const isDefaultValuesAllowed = useIsFeatureAllowed(
    FeatureEnum.TASK_DEFAULT_VALUES
  );

  return (
    <List
      title="Board Settings"
      items={[
        {
          id: "BoardUpdateInfoListItem",
          name: "Update Info",
          icon: <Settings />,
          path: `/${props.projectAbbr}/board/${props.boardRef}/settings/updateInfo`,
          navigateToPath: true,
          onNavigate: props.onClick,
        },
        {
          id: "BoardTaskDefaultValuesListItem",
          name: "Task Field Values",
          icon: <DriveFileRenameOutline />,
          path: `/${props.projectAbbr}/board/${props.boardRef}/settings/taskFieldValues`,
          navigateToPath: true,
          onNavigate: props.onClick,
          isDisabled: !isDefaultValuesAllowed,
          disabledTooltipMessage: featureUtil.notAvailableText,
        },
      ]}
    >
      <ArchiveBoard />
    </List>
  );
};

export default BoardSettingsSection;
