import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import {
  Autocomplete,
  Button,
  TextField,
} from "../../../../common/modules/materialUI";
import { TUpdateFormAttributes } from "./types/TUpdateFormAttributes";
import { Grid } from "@mui/material";
import { TUpdateFormProps } from "./types/TUpdateFormProps";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { useUpdateProjectService } from "../../services/useUpdateProjectService";
import { useNavigate } from "react-router-dom";
import { useFetchBoardListByProjectRefService } from "../../../board/services/useFetchBoardListByProjectRefService";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { TUpdateFormAttributesTransformed } from "./types/TUpdateFormAttributesTransformed";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { orgActions } from "../../../org/slices/orgSlice";
import { useEffect } from "react";
import { useUserListService } from "../../../user/services/useUserListService";
import ColorField from "../../../../common/modules/materialUI/components/TextField/ColorField";
import { useIsFeatureAllowed } from "../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../../common/modules/accessControl/utils/featureUtil";

const UpdateForm = (props: TUpdateFormProps) => {
  const hasProjectColorCoding = useIsFeatureAllowed(
    FeatureEnum.PROJECT_COLOR_CODING
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const board = useAppSelector((state) =>
    state.org.init.list
      .find(
        (data) => data.project.defaultBoardRef === props.data?.defaultBoardRef
      )
      ?.boards.find((board) => board.ref === props.data?.defaultBoardRef)
  );

  const form = useForm<TUpdateFormAttributes>();

  useEffect(() => {
    props.data &&
      form.changeDefaultData({
        abbr: props.data?.abbr,
        name: props.data?.name,
        color: props.data?.color,
        reps: props.data?.representatives.map((user) => {
          return {
            id: user?.id!,
            label: user?.name! || "",
          };
        }),
        defaultBoardRef: {
          id: board?.ref!,
          label: board?.name! || "",
        },
      } as TUpdateFormAttributes);
  }, [props.data, board]);

  const { isLoading, dispatch: dispatchUpdateProject } =
    useUpdateProjectService(props?.data?.ref);
  const fetchBoardListByProjectRefService =
    useFetchBoardListByProjectRefService({
      ref: props?.data?.ref,
    });
  const userListService = useUserListService();
  const isDisabled = isLoading || props.isDataLoading;

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchUpdateProject({
      body: form.getTransformed<TUpdateFormAttributesTransformed>((data) => ({
        name: data.name,
        abbr: data.abbr.toUpperCase(),
        color: hasProjectColorCoding ? data.color : undefined,
        reps: data.reps?.map((rep) => rep.id),
        defaultBoardRef: data.defaultBoardRef?.id,
      })),
    })
      .then(() => {
        dispatch(
          orgActions.updateProjectData({
            projectRef: board?.projectRef!,
            name: form.data?.name!,
            abbr: form.data?.abbr!.toUpperCase()!,
            color: form.data?.color || null,
            defaultBoardRef: form.data?.defaultBoardRef?.id!,
          })
        );
        form.notDirty();
        dispatch(
          systemNotificationActions.open({
            message: "Your project has been updated",
            variant: "success",
          })
        );
        navigate(`/project/${form?.data?.abbr.toUpperCase()}`, {
          replace: true,
        });
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            disabled={isDisabled || !form.isDirty()}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Save
          </Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            form={form}
            name="name"
            label="Name"
            showRequired
            disabled={isDisabled}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ColorField
            form={form}
            name="color"
            label="Color"
            disabled={isDisabled || !hasProjectColorCoding}
            helperText={
              !hasProjectColorCoding ? featureUtil.notAvailableText : undefined
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            form={form}
            name="defaultBoardRef"
            label="Default board"
            service={fetchBoardListByProjectRefService}
            showRequired
            disabled={isDisabled}
            options={[{ id: board?.ref!, label: board?.name! || "" }]}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            form={form}
            name="abbr"
            label="Abbreviation"
            showRequired
            disabled={isDisabled}
            helperText={`Tasks of this project will be prefixed with this abbreviation. For example: [ABC-123]. Changing this will not affect existing tasks.`}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Autocomplete
            form={form}
            key={props.data?.representatives.length}
            name="reps"
            label="Representatives"
            multiple
            disabled={isDisabled}
            service={userListService}
            options={props.data?.representatives.map((user) => {
              return {
                id: user?.id!,
                label: user?.name! || "",
              };
            })}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default UpdateForm;
