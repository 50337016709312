export enum FeatureEnum {
  AI_SENTINEL = "AI_SENTINEL",
  AUTO_SPLIT = "AUTO_SPLIT",
  AUTO_ADJUST = "AUTO_ADJUST",
  HOLISTIC_VIEW = "HOLISTIC_VIEW",
  OBJECTIVES = "OBJECTIVES",
  HOLISTIC_SHIFT = "HOLISTIC_SHIFT",
  HOLISTIC_RESIZE = "HOLISTIC_RESIZE",
  MULTI_ASSIGN = "MULTI_ASSIGN",
  MANUAL_SPLIT = "MANUAL_SPLIT",
  INITIATIVE_BOARD = "INITIATIVE_BOARD",
  FINANCIAL_FORECAST = "FINANCIAL_FORECAST",
  OFFTIME_MANAGEMENT = "OFFTIME_MANAGEMENT",
  NON_WORKDAY_CONTROL = "NON_WORKDAY_CONTROL",
  TEAMS_MANAGEMENT = "TEAMS_MANAGEMENT",
  STATUS_FLOW = "STATUS_FLOW",
  CUSTOM_FIELDS = "CUSTOM_FIELDS",
  TASK_DEFAULT_VALUES = "TASK_DEFAULT_VALUES",
  TASK_TYPE = "TASK_TYPE",
  TASK_PRIORITY = "TASK_PRIORITY",
  TASK_LINKS = "TASK_LINKS",
  PRIORITY_VIEW = "PRIORITY_VIEW",
  KANBAN_VIEW = "KANBAN_VIEW",
  LIST_VIEW = "LIST_VIEW",
  INTEGRATIONS = "INTEGRATIONS",
  OCCUPIED_SPACE = "OCCUPIED_SPACE",
  EVENT = "EVENT",
  SPRINT_CONTROLS = "SPRINT_CONTROLS",
  DASHBOARDS = "DASHBOARDS",
  BACKLOG_ICEBOX = "BACKLOG_ICEBOX",
  SHARING_BOARDS = "SHARING_BOARDS",
  GUESSTIMATION_BOARD = "GUESSTIMATION_BOARD",
  MONITORS = "MONITORS",
  TASK_STATES = "TASK_STATES",
  WORKFLOW_AUTOMATION = "WORKFLOW_AUTOMATION",
  SPIKE = "SPIKE",
  MOBILE_APP = "MOBILE_APP",
  CAPACITY_AND_VELOCITY = "CAPACITY_AND_VELOCITY",
  ADVANCED_ACCESS_CONTROL = "ADVANCED_ACCESS_CONTROL",
  WORKSPACE_CONTROL = "WORKSPACE_CONTROL",
  TASK_DELETE = "TASK_DELETE",
  WORKING_HOURS_CONTROL = "WORKING_HOURS_CONTROL",
  INITIATIVE_NOTES = "INITIATIVE_NOTES",
  MONTHLY_WEEKLY_REPORTS_INVOICES = "MONTHLY_WEEKLY_REPORTS_INVOICES",
  FIBONACCI_CONTROL = "FIBONACCI_CONTROL",
  RECURRING_TASKS = "RECURRING_TASKS",
  BATCH_CONTROLS = "BATCH_CONTROLS",
  PERFORMANCE_REPORTS = "PERFORMANCE_REPORTS",
  VIRTUAL_USERS = "VIRTUAL_USERS",
  PROJECTS = "PROJECTS",
  LIVE_SUPPORT = "LIVE_SUPPORT",
  FREE_SETUP = "FREE_SETUP",
  HOT_KEYS = "HOT_KEYS",
  PUBLIC_API = "PUBLIC_API",
  PROJECT_COLOR_CODING = "PROJECT_COLOR_CODING",
  TEAM_RESOURCE_AVAILABILITY = "TEAM_RESOURCE_AVAILABILITY",
}
