import { useParams } from "react-router-dom";
import { TBoardParams } from "../../../../containers/Board/types/TBoardParams";
import Title from "../../../../../../common/components/Title/Title";
import EntityFieldValuesForm from "./EntityFieldValuesForm";
import { useAppSelector } from "../../../../../../common/hooks/redux";
import NotFound from "../../../../../../common/components/Page/NotFound/NotFound";
import React from "react";
import Loader from "../../../../../../common/containers/Loader/Loader";
import { BoardTypeEnum } from "../../../../../../entities/columns/board/BoardTypeEnum";
import { useIsFeatureAllowed } from "../../../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../../../common/modules/accessControl/enums/FeatureEnum";

const TaskFieldValuesPage = () => {
  const { boardRef, projectAbbr } = useParams<TBoardParams>();
  const isDefaultValuesAllowed = useIsFeatureAllowed(
    FeatureEnum.TASK_DEFAULT_VALUES
  );
  const { isLoading: isOrgInitDataLoading, list } = useAppSelector(
    (state) => state.org.init
  );
  const board = list
    .find((data) => data.project.abbr === projectAbbr)
    ?.boards.find((board) => board.ref === boardRef);
  const entityLabel =
    board?.type === BoardTypeEnum.BACKLOG ? "Backlog Item" : "Task";

  if (!isDefaultValuesAllowed) {
    return <NotFound />;
  }

  return (
    <>
      {isOrgInitDataLoading && <Loader />}

      {!isOrgInitDataLoading && !board && <NotFound />}

      {!isOrgInitDataLoading && board && (
        <>
          <Title>{entityLabel} Field Values</Title>
          <EntityFieldValuesForm
            board={board}
            projectAbbr={projectAbbr!}
            entityLabel={entityLabel}
          />
        </>
      )}
    </>
  );
};

export default TaskFieldValuesPage;
