import List from "../../common/List/List";
import { useAccessControl } from "../../../../../../modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../../../modules/accessControl/enums/AbilityEnum";
import TListItemProps from "../../../../../../components/Navigation/ListItem/types/TListItemProps";
import { TSectionProps } from "../../types/TSectionProps";
import ProjectManagementListItem from "./ProjectManagementListItem";
import CustomFieldsListItem from "./CustomFieldsListItem";
import TaskLinkListItem from "./TaskLinkListItem";
import TaskTypesListItem from "./TaskTypesListItem";
import TaskPrioritiesListItem from "./TaskPrioritiesListItem";
import { useIsFeatureAllowed } from "../../../../../../modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../../../modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../../../../modules/accessControl/utils/featureUtil";

const AppSettingsSection = (props: TSectionProps) => {
  const { can } = useAccessControl();
  const isCustomFieldsAllowed = useIsFeatureAllowed(FeatureEnum.CUSTOM_FIELDS);

  const list: TListItemProps[] = can(AbilityEnum.administrate)
    ? [
        ProjectManagementListItem({ onClick: props.onClick }),
        CustomFieldsListItem({
          onClick: props.onClick,
          isDisabled: !isCustomFieldsAllowed,
          disabledTooltipMessage: featureUtil.notAvailableText,
        }),
        TaskLinkListItem({ onClick: props.onClick }),
        TaskTypesListItem({ onClick: props.onClick }),
        TaskPrioritiesListItem({ onClick: props.onClick }),
      ]
    : [];

  return <List title="Settings" items={list} />;
};

export default AppSettingsSection;
