import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { useParams } from "react-router-dom";
import { TBoardParams } from "../../../../board/containers/Board/types/TBoardParams";
import { pointConverter } from "../../../../../common/utils/pointConverter";
import { useRef, useState } from "react";
import { IGuesstimationItem } from "../../../../../entities/IGuesstimationItem";
import { guesstimationViewActions } from "../slices/guesstimationViewSlice";
import {
  IResizeGuesstimationItemServiceRequest,
  useResizeGuesstimationItemService,
} from "../../../../guesstimation/modules/view/services/useResizeGuesstimationItemService";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import moment from "moment/moment";
import { FORMAT_RAW_DATE_ONLY } from "../../../../../common/utils/date";
import { IGuesstimationItemDetailsResource } from "../../../../guesstimation/modules/view/interfaces/IGuesstimationItemDetailsResource";
import { EventEnum } from "../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../common/modules/eventProvider";
import { TGuesstimationItemUpdatedEvent } from "../../../../guesstimation/modules/view/containers/types/TGuesstimationItemUpdatedEvent";

const initialState = {
  days: 0,
  remainingPixelsToNextCheckpoint: 0,
  widthDiff: 0,
  xPositive: undefined,
};

type TProps = { guesstimationItem: IGuesstimationItem };

export const useResizeHelper = ({ guesstimationItem }: TProps) => {
  const [state, setState] = useState<{
    days: number;
    remainingPixelsToNextCheckpoint: number;
    widthDiff: number;
    xPositive?: boolean;
  }>(initialState);

  const daysRef = useRef<number>(0);

  const workingHours = useAppSelector(
    (state) => state.holisticView.workingHours
  );
  const { projectAbbr, boardRef } = useParams<TBoardParams>();
  // @TODO change if needed or drop if not gonna use
  // const { dispatch: dispatchUpdateUserLane } = useUpdateUserLanes({
  //   projectAbbr: projectAbbr!,
  //   boardRef: boardRef!,
  // });
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const { dispatch: dispatchResizeGuesstimationItemService } =
    useResizeGuesstimationItemService(guesstimationItem.id);
  const errorHandler = (error: THttpClientError) => {
    if ([406, 403].includes(error.status)) {
      dispatch(
        systemNotificationActions.open({
          message: error.data.message,
          variant: "warning",
        })
      );
      return;
    }

    dispatch(
      systemNotificationActions.open({
        message: "Something went wrong, please try again.",
        variant: "error",
      })
    );
  };

  // const stepInPx = pointConverter.oneWorkingHourInPixels(workingHours);
  const stepInPx = pointConverter.oneTSPointToPixels;

  const resizeStartHandler = () =>
    dispatch(guesstimationViewActions.displayLaneOverlay(true));

  const calculatePixelsToNextCheckpoint = (
    width: number,
    increasing = true
  ) => {
    const remainder = Math.abs(width % stepInPx);

    if (increasing) {
      if (width >= 0) {
        return stepInPx - remainder;
      } else {
        return remainder;
      }
    } else {
      if (width >= 0) {
        return remainder;
      } else {
        return stepInPx - remainder;
      }
    }
  };

  const resizeHandler = (
    e: MouseEvent,
    widthDiff: number,
    xPositive?: boolean
  ) => {
    const toWorkingDays = widthDiff / stepInPx;
    const roundedDays =
      toWorkingDays > 0 ? Math.ceil(toWorkingDays) : Math.floor(toWorkingDays);

    daysRef.current = roundedDays;
    setState({
      days: roundedDays,
      remainingPixelsToNextCheckpoint: calculatePixelsToNextCheckpoint(
        widthDiff,
        xPositive
      ),
      widthDiff: widthDiff,
      xPositive: xPositive,
    });
  };

  const resizeCompleteHandler = (resizeResetHandler: () => void) => {
    // Fetch the latest state
    const days = daysRef.current;
    if (days === 0) {
      reset(resizeResetHandler);
      return;
    }

    dispatch(guesstimationViewActions.animateLaneOverlay(true));

    // Trigger the resize request
    dispatchResizeGuesstimationItemService({
      body: {
        dueDate: moment(guesstimationItem.dueDate)
          .add(days, "days")
          .format(FORMAT_RAW_DATE_ONLY)
          .toString(),
      } as IResizeGuesstimationItemServiceRequest,
    })
      .then((resource: IGuesstimationItemDetailsResource) => {
        // Updating the guesstimation item in the state (for better UI experience)
        dispatch(
          guesstimationViewActions.updateGuesstimationItem(
            resource.guesstimationItem
          )
        );

        // Raising an update event to update the board (e.g. so that min/max dates would be recalculated)
        dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_UPDATED, {
          initGuesstimationItem: guesstimationItem,
          guesstimationItem: resource.guesstimationItem,
        } as TGuesstimationItemUpdatedEvent);

        dispatch(
          systemNotificationActions.open({
            message: `Guesstimation Item ${guesstimationItem.ref} has been successfully resized`,
            variant: "success",
          })
        );

        resizeEndHandler();
      })
      .catch((error: THttpClientError) => {
        errorHandler(error);
        reset(resizeResetHandler);
      });
  };

  const reset = (resizeResetHandler: () => void) => {
    resizeEndHandler();
    // Reset the task width to its initial value
    resizeResetHandler();
  };

  const resizeEndHandler = () => {
    dispatch(guesstimationViewActions.displayLaneOverlay(false));
    setState(initialState);
    daysRef.current = 0;
  };

  const resizeInterruptHandler = () => {
    resizeEndHandler();
  };

  const calculateMetrics = (days: number) => {
    if (days === 0) {
      return undefined;
    }

    const absoluteDays = Math.abs(days);

    const daysString =
      absoluteDays !== 0
        ? `${absoluteDays} day${absoluteDays > 1 ? "s" : ""}`
        : "";

    return `${!(days > 0) ? "-" : "+"} ${daysString}`.trim();
  };

  return {
    days: state.days,
    xPositive: state.xPositive,
    widthDiff: state.widthDiff,
    remainingPixelsToNextCheckpoint:
      state.remainingPixelsToNextCheckpoint > 0
        ? state.remainingPixelsToNextCheckpoint
        : stepInPx,
    metrics: calculateMetrics(state.days),
    resizeHandler,
    resizeStartHandler,
    resizeCompleteHandler,
    resizeInterruptHandler,
  };
};
