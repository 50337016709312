import { Grid, useTheme } from "@mui/material";
import { EntityTypeEnum } from "../../../../../entities/enums/EntityTypeEnum";
import { TGuesstimationItemContentProps } from "./types/TGuesstimationItemContentProps";
import AddAttachment from "../../../../../common/modules/taskView/containers/QuickActions/containers/AddAttachment";
import QuickActions from "../../../../../common/modules/taskView/containers/QuickActions/QuickActions";
import GuesstimationItemViewHeader from "./Header/GuesstimationItemViewHeader";
import StatusForm from "./Status/StatusForm";
import GuesstimationItemViewMainContent from "./GuesstimationItemViewMainContent/GuesstimationItemViewMainContent";
import DeleteGuesstimationItem from "./QuickActions/containers/DeleteGuesstimationItem";
import { MAIN_CONTENT_PADDING_BOTTOM } from "../../../../../common/containers/Layout/App/Content/Content";

const GuesstimationItemViewMobile = ({
  detailsResource,
  contentHeight,
  isMobileView,
  isPageView,
}: TGuesstimationItemContentProps) => {
  const theme = useTheme();

  const { guesstimationItem } = detailsResource;
  const entityType = EntityTypeEnum.GUESSTIMATION_ITEM;
  const id = guesstimationItem.id;

  return (
    <Grid
      container
      minHeight={contentHeight}
      flexDirection="column"
      wrap="nowrap"
    >
      <Grid item>
        <GuesstimationItemViewHeader
          guesstimationItem={guesstimationItem}
          isMobileView={isMobileView}
          isPageView={isPageView}
        />
      </Grid>

      <Grid
        container
        item
        bgcolor={theme.palette.taskView.mainBackground}
        p="20px 16px 40px"
        pb={`${MAIN_CONTENT_PADDING_BOTTOM}px`}
        flex={1}
        flexDirection="column"
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          mb="20px"
        >
          <Grid item>
            <StatusForm guesstimationItem={guesstimationItem} />
          </Grid>

          <Grid item>
            <QuickActions isMobileView={isMobileView}>
              <AddAttachment
                isMobileView={isMobileView}
                entityType={entityType}
                entityId={id}
              />
              <DeleteGuesstimationItem guesstimationItem={guesstimationItem} />
            </QuickActions>
          </Grid>
        </Grid>

        <GuesstimationItemViewMainContent
          resource={detailsResource}
          isMobileView={isMobileView}
          isPageView={isPageView}
        />
      </Grid>
    </Grid>
  );
};

export default GuesstimationItemViewMobile;
