import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment/moment";
import { FORMAT_RAW_DATE_ONLY } from "../../../../../common/utils/date";
import { IGuesstimationViewDataResource } from "../../../interfaces/IGuesstimationViewDataResource";
import { IGuesstimationItem } from "../../../../../entities/IGuesstimationItem";

export const timelineDisplayMonths = 2;

const calculateTimelineDisplayFromDate = (
  minDate: IGuesstimationViewDataResource["minDate"]
) =>
  moment(minDate ?? undefined)
    .subtract(timelineDisplayMonths / 2, "month")
    .startOf("day")
    .format(FORMAT_RAW_DATE_ONLY);
const calculateTimelineDisplayToDate = (
  maxDate: IGuesstimationViewDataResource["maxDate"]
) =>
  moment(maxDate ?? undefined)
    .add(timelineDisplayMonths / 2, "month")
    .format(FORMAT_RAW_DATE_ONLY);
const shouldExpandTimelineDisplayFromDate = (
  currentValue: string,
  newValue: IGuesstimationViewDataResource["minDate"]
) => newValue && moment(newValue).isBefore(moment(currentValue));
const shouldExpandTimelineDisplayToDate = (
  currentValue: string,
  newValue: IGuesstimationViewDataResource["maxDate"]
) => newValue && moment(newValue).isAfter(moment(currentValue));

type TInitialState = {
  data: IGuesstimationViewDataResource;
  showSkeleton: boolean;
  workingHours: number;
  // data?: THolisticViewData;
  // havingBoardUsers: boolean;
  laneOverlay?: {
    display?: boolean;
    animate?: boolean;
  };
  // displayCostPerItem: boolean;
  // virtualizationParams: {
  //   weeksToLoad: number;
  //   loadFromDate: string;
  //   loadDirection: LoadDirectionEnum;
  //   lastReset?: string;
  // };
  timelineDisplayFromDate: string;
  timelineDisplayToDate: string;
  filters: {
    showDone: boolean;
  };
};

const initialState: TInitialState = {
  workingHours: 8,
  data: {
    sprints: [],
    guesstimationItems: [],
    minDate: null,
    maxDate: null,
    users: [],
    nonWorkdays: [],
    collisionsKpi: [],
  },
  showSkeleton: true,
  // havingBoardUsers: false,
  // displayCostPerItem: false,
  // virtualizationParams: {
  //   weeksToLoad: windowHelper.widthToWeeks(),
  //   loadFromDate: moment().toISOString(),
  //   loadDirection: LoadDirectionEnum.INIT,
  // },
  timelineDisplayFromDate: calculateTimelineDisplayFromDate(null),
  timelineDisplayToDate: calculateTimelineDisplayToDate(null),
  filters: {
    showDone: false,
  },
};

const slice = createSlice({
  name: "guesstimationView",
  initialState,
  reducers: {
    setWorkingHours: (
      state,
      action: PayloadAction<TInitialState["workingHours"]>
    ) => {
      state.workingHours = action.payload;
    },
    // resetVirtualizationData: (state) => {
    //   state.virtualizationParams = {
    //     ...initialState.virtualizationParams,
    //     lastReset: moment().toISOString(),
    //   };
    // },
    setData: (state, action: PayloadAction<IGuesstimationViewDataResource>) => {
      state.data = action.payload;
      state.showSkeleton = false;

      const newTimelineDisplayFromDate = calculateTimelineDisplayFromDate(
        action.payload.minDate
      );
      const newTimelineDisplayToDate = calculateTimelineDisplayToDate(
        action.payload.maxDate
      );
      if (
        shouldExpandTimelineDisplayFromDate(
          state.timelineDisplayFromDate,
          newTimelineDisplayFromDate
        )
      ) {
        state.timelineDisplayFromDate = newTimelineDisplayFromDate;
      }
      if (
        shouldExpandTimelineDisplayToDate(
          state.timelineDisplayToDate,
          newTimelineDisplayToDate
        )
      ) {
        state.timelineDisplayToDate = newTimelineDisplayToDate;
      }
    },
    updateGuesstimationItem: (
      state,
      action: PayloadAction<IGuesstimationItem>
    ) => {
      state.data.guesstimationItems = state.data.guesstimationItems.map(
        (item) => (item.id === action.payload.id ? action.payload : item)
      );
    },
    displayLaneOverlay: (state, action: PayloadAction<boolean>) => {
      !state.laneOverlay && (state.laneOverlay = {});
      state.laneOverlay.display = action.payload;
      !action.payload && (state.laneOverlay.animate = false);
    },
    animateLaneOverlay: (state, action: PayloadAction<boolean>) => {
      !state.laneOverlay && (state.laneOverlay = {});
      state.laneOverlay.animate = action.payload;
    },
    // displayCostPerItem: (state, action: PayloadAction<boolean>) => {
    //   state.displayCostPerItem = action.payload;
    // },
    // setVirtualizationParams: (
    //   state,
    //   action: PayloadAction<{
    //     data: THolisticViewState["virtualizationParams"];
    //   }>
    // ) => {
    //   if (
    //     state.virtualizationParams.weeksToLoad !==
    //       action.payload.data.weeksToLoad ||
    //     state.virtualizationParams.loadFromDate !==
    //       action.payload.data.loadFromDate ||
    //     state.virtualizationParams.loadDirection !==
    //       action.payload.data.loadDirection
    //   ) {
    //     state.virtualizationParams = action.payload.data;
    //   }
    // },
    expandTimelineDisplayFromDate(state) {
      state.data.minDate = moment(state.data.minDate)
        .subtract(timelineDisplayMonths / 2, "month")
        .startOf("day")
        .format(FORMAT_RAW_DATE_ONLY);
    },
    expandTimelineDisplayToDate(state) {
      state.data.maxDate = moment(state.data.maxDate)
        .add(timelineDisplayMonths / 2, "month")
        .format(FORMAT_RAW_DATE_ONLY);
    },
    // setHavingBoardUsers: (state, action: PayloadAction<boolean>) => {
    //   state.havingBoardUsers = action.payload;
    // },

    // filters
    toggleShowDone: (state) => {
      state.filters.showDone = !state.filters.showDone;
    },

    reset: () => initialState,
  },
});

export const guesstimationViewReducer = slice.reducer;

export const guesstimationViewActions = slice.actions;
