import { createTheme } from "@mui/material";
import { env } from "../../utils/env";

const formDisabledColor = "rgba(141, 138, 138, 0.7)";
const formTextColor = "#E5E1E1";
const formLabelColor = "rgba(225, 222, 222, 0.7)";

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    dropTargetBackground: {
      default: "#4a7879",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#EDEDED",
      secondary: "#c2c2c2",
    },
    primary: {
      main: "#278D76",
      contrastText: "#FEFEFE",
    },
    background: {
      default: "#21262C",
      paper: "#1e242b",
    },
    brightCardBackground: {
      highlightColor: "#EDEDED",
      default: "#353B44",
      paper: "#272e37",
      main: "#aeb7bb",
      contrastText: "#5e6a6d",
    },
    taskView: {
      mainBackground: "#323841",
      secondaryBackground: "#2A3138",
      mainContentBackground: "#2A2F38",
      headerTitleBackground: "rgba(255, 255, 255, 0.05)",
      aiButtonBackground: "#F6F7FB",
      dividerBackground: "rgba(255, 255, 255, 0.10)",
      attachmentDividerBackground: "rgba(51, 55, 68, 0.78)",
      headerBorderBottomBackground: "rgba(255, 255, 255, 0.10)",
      dropContainerBackground: "rgba(53, 59, 68, 0.7)",
      editorExpandGradient:
        "linear-gradient(180deg, rgba(42, 47, 56, 0.00) 0%, #2A2F38 72.56%)",
      textMainColor: "#F6F7FB",
      textSecondaryColor: "#F6F7FB",
      labelColor: "#B6B6B6",
      quickActionsLinkColor: "#2A3138",
      descriptionAiIcon: `${env.cdnUrl}/components/ai-sentinel/ai-sentinel-icon-dark.png`,
    },
    brightCardShadowColor: {
      primary: "inset 0 0 10px #21262C, 0 0 0 #21262C",
      secondary: "inset 0 0 4px #21262C, 0 0 0 #21262C",
    },
    mutedCardBackground: {
      default: "#313840",
      paper: "#39404A",
      main: "#aeb7bb",
    },
    cardsBackground: {
      default: "#353B44",
      paper: "#292f36",
      main: "#999999",
    },
    cardSplitBorder: {
      main: "2px dashed #515C68",
    },
    inputPallet: {
      main: formTextColor,
    },
    loginPallet: {
      main: "#282d34",
    },
    pickerPallet: {
      primary: "#FFFFFF",
      secondary: "#bdc2c3",
    },
    boardSideViewShadow: {
      main: "rgb(33, 38, 44) -47px -20px 20px -40px inset",
    },
    error: {
      main: "#D33939",
      dark: "#aa3434",
    },
    risk: {
      main: "#ffaf42",
    },
    warning: {
      main: "#ff8155",
    },
    info: {
      main: "#15a6a9",
    },
    success: {
      main: "#278D76",
    },
    successLight: {
      main: "#50c878",
    },
    dataGrid: {
      borderColor: "#424850",
      footerBackground: "#292f36",
      headerBackground: "#292f36",
      rowOddBackground: "#262c33",
      rowEvenBackground: "#242a31",
      rowHoverBackground: "#ffffff14",
    },
    landingPageCard: {
      default: "#272E37",
      paper: "#353b44",
    },
    event: {
      background: "rgb(117 152 169 / 14%)",
      color: "#a1b7bb",
      backgroundImgSrc: `${env.cdnUrl}/components/event/event-lines-dark.png`,
      shadow: "rgb(27 33 40) 0px 0px 10px inset",
      icon: `${env.cdnUrl}/components/event/calendar-dark-theme.svg`,
    },
    offtime: {
      backgroundImgSrc: `${env.cdnUrl}/components/offtime/offtime-line-dark.png`,
    },
    occupied: {
      color: "#4b5156",
      backgroundImgSrc: `${env.cdnUrl}/components/occupied-space/occupied-lines-dark-theme-v2.png`,
      shadow: "0px 0px 4px 0px rgba(0,0,0,0.2)",
    },
    spike: {
      backgroundColor: "#e8d584",
      color: "#353c4e",
      border: "2px solid #ae973e",
      backgroundImgSrc: `${env.cdnUrl}/components/spike/spike-icon-dark.png`,
      xsImg: `${env.cdnUrl}/components/spike/xsspike-icon-dark.png`,
    },
    floatingActions: {
      buttonBackgroundColor: "#353b44",
    },
    aiSentinel: {
      aiChatBackgroundColor: "#353b44",
      userChatBackgroundColor: "#282c32",
      chatGeneralBackgroundColor: "#30353d",
      headerBackgroundColor: "#292f35",
      headerIconBackgroundColor: "#f0f0ef",
      chatRequiredFieldsColor: "#AAABB0",
      chatRequiredFieldsBackgroundColor: "#30343B",
      regularIcon: `${env.cdnUrl}/components/ai-sentinel/ai-sentinel-icon-light.png`,
      headerIcon: `${env.cdnUrl}/components/ai-sentinel/ai-sentinel-icon-dark.png`,
    },
    helpCenter: {
      liveChatIcon: `${env.cdnUrl}/components/help-center/live-chat-icon-dark.png`,
    },
    statusTypeSchema: {
      toDo: { color: "#3a424a" },
      inProgress: { color: "#1b5054" },
      done: { color: "#3e876a" },
      textColor: "#fff",
      buttonBackground: "#2e3338",
    },
    nonWorkday: {
      timelineColor: "#51DBBC",
    },
    sprint: {
      main: "#579085",
      contrastText: "#e7e4e4",
    },
    avatar: {
      color: "#F6F7FB",
      border: "3px solid #579085",
    },
    circularProgressBar: {
      strokeColor: "#90caf9",
      strokeBackgroundColor: "rgba(255, 255, 255, 0.3)",
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: (_) => ({
          backgroundImage: "linear-gradient(#21262C, #21262C)",
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            "0px 1px 1px -1px rgba(174,183,187,0.2), 0px 1px 1px 0px rgba(174,183,187,0.14), 0px 1px 3px 0px rgba(174,183,187,0.12)",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: formTextColor,
          "&.Mui-disabled:before": {
            borderBottom: `1px solid ${formDisabledColor}`,
            borderBottomStyle: "dotted",
          },
          "&:not(.Mui-disabled) .MuiIconButton-root": {
            color: formLabelColor,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: formLabelColor,
          "&.Mui-disabled": {
            color: formDisabledColor,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: formDisabledColor,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: formDisabledColor,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          ".MuiTypography-root": {
            color: formLabelColor,
          },
          "&.Mui-disabled": {
            ".MuiTypography-root": {
              color: formDisabledColor,
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          // Required for showing Autocomplete options inside AI Sentinel when any Dialog is opened
          zIndex: 1500,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          // Required for showing date picker dialog inside AI Sentinel: above the chat and above any opened Dialog
          zIndex: 1500,
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        style: {
          // Required for showing date picker dialog inside AI Sentinel: above the chat and above any opened Dialog
          zIndex: 1500,
        },
      },
    },
  },
});
