import { Grid, IconButton, List, ListItem, ListItemText } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import {
  AccountTree,
  AddRoad,
  EventBusy,
  Inventory,
  ModeEdit,
} from "@mui/icons-material";
import { IProject } from "../../../../entities/IProject";
import { useNavigate } from "react-router-dom";
import { useArchiveProjectService } from "../../services/useArchiveProjectService";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import Loader from "../../../../common/containers/Loader/Loader";
import { useFetchProjectListService } from "../../services/useFetchProjectListService";
import { projectActions } from "../../slices/projectSlice";
import {
  ConfirmationButton,
  Tooltip,
} from "../../../../common/modules/materialUI";
import { navigationActions } from "../../../../common/store/navigationSlice";
import { orgActions } from "../../../org/slices/orgSlice";
import { useIsFeatureAllowed } from "../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../../common/modules/accessControl/utils/featureUtil";

const ProjectList = () => {
  const { isLoading } = useFetchProjectListService();
  const isNonWorkdayAllowed = useIsFeatureAllowed(
    FeatureEnum.NON_WORKDAY_CONTROL
  );
  const data = useAppSelector((state) => state.project.projectList);
  const sprintLabel = useAppSelector((state) => state.customs.sprintLabel);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dispatch: dispatchArchiveProject } = useArchiveProjectService();

  const archiveProjectHandler = (item: IProject) => {
    dispatch(projectActions.removeProject({ ref: item.ref }));
    dispatch(orgActions.removeProject({ ref: item.ref }));
    dispatchArchiveProject({ urlPath: `${item.ref}/archive` })
      .then(() => {
        dispatch(
          systemNotificationActions.open({
            message:
              "Your archive request has been received successfully. It may take some time for all project-related components to be archived across all locations.",
            variant: "success",
          })
        );
      })
      .catch(() => {
        dispatch(navigationActions.refresh());
        dispatch(projectActions.addProject(item));
        dispatch(
          systemNotificationActions.open({
            message: "Unable to archive, please try again.",
            variant: "error",
          })
        );
      });
  };

  const editProjectHandler = (abbr: string) => {
    navigate(`/project/${abbr}`);
  };

  const editStatusFlowsHandler = (abbr: string) => {
    navigate(`/settings/project/${abbr}/statusFlow`);
  };

  const configNonWorkingDaysHandler = (abbr: string) => {
    navigate(`/settings/project/${abbr}/configNonWorkingDays`);
  };

  const manageSprintsHandler = (abbr: string) => {
    navigate(`/settings/project/${abbr}/sprints`);
  };

  return (
    <>
      {isLoading && <Loader />}
      <Grid container>
        <Grid item xs={12} md={6}>
          <List>
            {data?.map((item, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <>
                    <Tooltip title="Edit Project">
                      <IconButton
                        onClick={(_) => editProjectHandler(item.abbr)}
                      >
                        <ModeEdit fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Edit Status Flow">
                      <IconButton
                        onClick={(_) => editStatusFlowsHandler(item.abbr)}
                      >
                        <AccountTree fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      title={`Config Non Working Days${
                        !isNonWorkdayAllowed
                          ? ` (${featureUtil.notAvailableText})`
                          : ""
                      }`}
                    >
                      <IconButton
                        onClick={(_) => {
                          isNonWorkdayAllowed
                            ? configNonWorkingDaysHandler(item.abbr)
                            : dispatch(
                                systemNotificationActions.open({
                                  message: featureUtil.notAvailableFullText,
                                  variant: "warning",
                                })
                              );
                        }}
                      >
                        <EventBusy fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={`Manage ${sprintLabel}s`}>
                      <IconButton
                        onClick={(_) => manageSprintsHandler(item.abbr)}
                      >
                        <AddRoad fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <ConfirmationButton
                      title="Archive Project"
                      content="Are you sure you want to archive project? You won't be able to unarchived it manually."
                      button={
                        <Tooltip title="Archive Project">
                          <IconButton color="error">
                            <Inventory color="error" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      }
                      data={item}
                      successHandler={archiveProjectHandler}
                    />
                  </>
                }
              >
                <ListItemText
                  primaryTypographyProps={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    marginRight: "2rem",
                  }}
                  primary={item.name}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectList;
