import { Box, Typography } from "@mui/material";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { taskViewActions } from "../../slices/viewSlice";
import { Archive } from "@mui/icons-material";
import { TMoveToBacklogMenuItemProps } from "./types/TMoveToBacklogMenuItemProps";
import { useIsFeatureAllowed } from "../../../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../../../common/modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../../../../common/modules/accessControl/utils/featureUtil";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";

const MoveToBacklogMenuItem = ({ taskId }: TMoveToBacklogMenuItemProps) => {
  const dispatch = useAppDispatch();
  const isBacklogAllowed = useIsFeatureAllowed(FeatureEnum.BACKLOG_ICEBOX);

  return menuItem.make(
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      onClick={() =>
        isBacklogAllowed
          ? dispatch(taskViewActions.openMoveToBacklogConfirmation({ taskId }))
          : dispatch(
              systemNotificationActions.open({
                message: featureUtil.notAvailableFullText,
                variant: "warning",
              })
            )
      }
    >
      <Archive fontWeight="400" />
      <Typography variant="subtitle2" fontWeight="400">
        Move to backlog
      </Typography>
    </Box>,
    undefined,
    undefined,
    !isBacklogAllowed,
    featureUtil.notAvailableText
  );
};

export default MoveToBacklogMenuItem;
