import { useAppSelector } from "../../../../../common/hooks/redux";
import { IGuesstimationViewDataRequest } from "../../../interfaces/IGuesstimationViewDataRequest";

type TProps = {
  projectAbbr: string;
};

export const useGuesstimationViewQueryParams = ({
  projectAbbr,
}: TProps): IGuesstimationViewDataRequest => {
  const { userIds } = useAppSelector((state) => state.boardQuery.filters);

  const { showDone } = useAppSelector(
    (state) => state.guesstimationView.filters
  );

  return {
    projectAbbr: projectAbbr,
    sprints: 1,
    filters: {
      ...(userIds.length > 0 && { users: userIds }),
      showDone: showDone ? 1 : 0,
    },
  };
};
