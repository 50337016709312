import classes from "./Lane.module.scss";
import moment from "moment/moment";
import { useTheme } from "@mui/material";
import { pointConverter } from "../../../../../../common/utils/pointConverter";
import LaneItem from "../LaneItem/LaneItem";
import Overlay from "./Overlay";
import { TLaneProps } from "./types/TLaneProps";
import DropTargetContainer from "../DropTarget/DropTargetContainer";

const POSITION_SHIFT = 5;
export const GUESSTIMATION_ITEM_LANE_HEIGHT = 85;

const Lane = (props: TLaneProps) => {
  const theme = useTheme();
  const xSize = pointConverter.pointToXSizeInPixels(
    props.guesstimationItem.tsPoints
  );
  // This will force tasks to re-render on user lane update, even if service sent same data
  // const suffix = laneData.keySuffix ? "-" + laneData.keySuffix : "";
  // const key = `task-${task.segmentId}${suffix}`;

  return (
    <div
      className={classes.container}
      style={{
        width: props.width,
        minHeight: `${GUESSTIMATION_ITEM_LANE_HEIGHT}px`,
      }}
    >
      <div
        className={classes.user}
        style={{
          backgroundColor: theme.palette.cardsBackground.paper,
          color: theme.palette.cardsBackground.main,
          fontWeight: "inherit",
        }}
      >
        <div>{props.guesstimationItem.ref}</div>
      </div>
      <DropTargetContainer {...props} />
      <div
        className={classes["task-lane"]}
        style={{ backgroundColor: theme.palette.cardsBackground.default }}
      >
        <Overlay />
        <LaneItem
          itemLaneIndex={props.guesstimationItem.id}
          guesstimationItem={props.guesstimationItem}
          size={xSize - POSITION_SHIFT}
          positionX={pointConverter.intervalToXPositionInPixels(
            props.minDate,
            moment(props.guesstimationItem.startDate)
          )}
          users={props.users}
        />
      </div>
    </div>
  );
};

export default Lane;
