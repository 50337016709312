import { TAddAssigneesFormProps } from "./types/TAddAssigneesFormProps";
import {
  Autocomplete,
  Button,
  DatePicker,
  TextField,
} from "../../../../../../common/modules/materialUI";
import React from "react";
import Form from "../../../../../../common/components/Form/Form";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import { TAddAssigneesFormAttributes } from "./types/TAddAssigneesFormAttributes";
import { Grid } from "@mui/material";
import { enumUtil } from "../../../../../../common/utils/enum";
import { EstimationUomEnum } from "../../../../../../entities/enums/EstimationUomEnum";
import { useUserListService } from "../../../../../user/services/useUserListService";
import { AddAssigneesTypeEnum } from "../../slices/enums/AddAssigneesTypeEnum";
import { TListResource } from "../../../../../../common/types/TListResource";
import { StartDateTypeEnum } from "../../../../enums/StartDateTypeEnum";
import { TListItem } from "../../../../../../common/types/TListItem";
import { useAddAssigneesService } from "../../../../services/useAddAssigneesService";
import { ITask } from "../../../../../../entities/ITask";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { TAddAssigneesFormAttributesTransformed } from "./types/TAddAssigneesFormAttributesTransformed";
import { taskActions } from "../../../../slices/taskSlice";
import { taskStartDateUtil } from "../../../../utils/taskStartDateUtil";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";

const AddAssigneesForm = ({
  taskId,
  type,
  onCancel,
}: TAddAssigneesFormProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const form = useForm<TAddAssigneesFormAttributes>({
    taskId: taskId,
    type: type,
    assignedTo: [],
  });
  const { isLoading, dispatch: dispatchAddAssignees } = useAddAssigneesService({
    taskId,
  });

  const userListService = useUserListService();

  const selectedAssignedTo: TListResource<number> = form.get("assignedTo");
  const selectedStartDateType: TListItem<string> | null =
    form.get("startDateType");
  const startDateTypeHelperText = taskStartDateUtil.getStartDateTypeHelperText(
    selectedStartDateType
  );
  const isStartDateTypeOnSelectedDate =
    selectedStartDateType?.id === StartDateTypeEnum.ON_SELECTED_DATE;

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchAddAssignees({
      body: form.getTransformed<TAddAssigneesFormAttributesTransformed>(
        (data) => {
          return {
            ...data,
            estimationUom: data.estimationUom?.id,
            assignedTo: data.assignedTo?.length
              ? data.assignedTo.map((assignedToItem) => assignedToItem.id)
              : undefined,
            startDateType: data.startDateType?.id,
            selectedStartDate: data.selectedStartDate
              ?.startOf("day")
              .format("DD-MM-YYYY HH:mm")
              .toString(),
          };
        }
      ),
    })
      .then((payload: ITask[]) => {
        payload.forEach((task) => {
          dispatchEvent(EventEnum.ON_TASK_CREATED, task);
        });
        const lastTask = payload[payload.length - 1];
        dispatch(
          taskActions.mergeTaskProps({
            aggStatus: lastTask.aggStatus,
            aggState: lastTask.aggState,
            aggEstimationPoints: lastTask.aggEstimationPoints,
            aggEstimationUom: lastTask.aggEstimationUom,
            aggTsPoints: lastTask.aggTsPoints,
            assignees: lastTask.assignees,
            segmentCount: lastTask.segmentCount,
            hasSplits: lastTask.hasSplits,
            aggStartDate: lastTask.aggStartDate,
            aggDueDate: lastTask.aggDueDate,
          })
        );
      })
      .catch((error: THttpClientError) => {
        if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
          return;
        }

        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button onClick={onCancel} disabled={isLoading} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Add
          </Button>
        </>
      }
    >
      {/* Estimations */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            form={form}
            name="estimationUom"
            label="Estimation UOM"
            options={enumUtil.toList(EstimationUomEnum)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField form={form} name="estimationPoints" label="Estimation" />
        </Grid>
      </Grid>

      {/* Assignee */}
      <Autocomplete
        form={form}
        name="assignedTo"
        label="Assigned To"
        multiple
        showRequired={type === AddAssigneesTypeEnum.ADD_ASSIGNEE}
        service={userListService}
        options={form.data?.assignedTo}
        textFieldProps={{
          helperText:
            selectedAssignedTo.length > 1
              ? "Each segment estimation will be the same for each assignee"
              : undefined,
        }}
      />

      {/* Start Date */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={isStartDateTypeOnSelectedDate ? 6 : 12}>
          <Autocomplete
            form={form}
            name="startDateType"
            label="Start Date"
            options={enumUtil.toList(StartDateTypeEnum)}
            textFieldProps={{
              helperText: startDateTypeHelperText,
            }}
            disabled={!selectedAssignedTo.length}
          />
        </Grid>
        {isStartDateTypeOnSelectedDate && (
          <Grid item xs={12} sm={6}>
            <DatePicker
              form={form}
              name="selectedStartDate"
              label="Date"
              showRequired
            />
          </Grid>
        )}
      </Grid>
    </Form>
  );
};

export default AddAssigneesForm;
