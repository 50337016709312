import { Grid, Typography } from "@mui/material";
import AddButton from "../AddButton/AddButton";
import GuesstimationViewContent from "../GuesstimationViewContent/GuesstimationViewContent";
import { TBoardViewProps } from "../../../../../board/types/TBoardViewProps";
import { StickyNote2 } from "@mui/icons-material";
import { Tooltip } from "../../../../../../common/modules/materialUI";
import React, { useState } from "react";
import { useAppSelector } from "../../../../../../common/hooks/redux";
import NotesModal from "../../../../../objective/containers/View/Initiatives/ManageInitiative/NotesModal";
import MoreOptionsMenu from "../../../../../../common/containers/Menu/MoreOptionsMenu";
import DisplayDoneOption from "../Filter/More/DisplayDoneOption";
import CreateModal from "../../../../../guesstimation/modules/create/containers/CreateItem/CreateModal";
import GuesstimationItemViewModal from "../../../../../guesstimation/modules/view/containers/GuesstimationItemViewModal";
import BoardUsersFilter from "../Filter/BoardUsersFilter";

const GuesstimationView = (props: TBoardViewProps) => {
  const [noteModalOpen, setNoteModalOpen] = useState<boolean>(false);
  const initiatives = useAppSelector((state) => state.board.initiatives);
  const { data, showSkeleton } = useAppSelector(
    (state) => state.guesstimationView
  );

  return (
    <>
      <Grid
        container
        spacing={3}
        mb={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <AddButton />
            </Grid>
            <Grid item>
              <BoardUsersFilter
                projectAbbr={props.projectAbbr}
                boardRef={props.boardRef}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container alignItems="center">
            {/*Collisions KPI*/}
            {!!data.collisionsKpi.length && (
              <Grid item sx={{ mr: 2 }}>
                <Grid container alignItems="center" spacing={1}>
                  {data.collisionsKpi.map((collisionKpi) => {
                    return (
                      <Grid item key={collisionKpi.userRole}>
                        <Typography variant="body2">{`${collisionKpi.userRole} (-${collisionKpi.count})`}</Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}

            <Grid item>
              <MoreOptionsMenu
                closeOnSelection={false}
                menuItems={[DisplayDoneOption()]}
              />
            </Grid>

            <Grid item>
              <Tooltip title="Notes">
                <StickyNote2
                  style={{
                    cursor: "pointer",
                    marginLeft: 15,
                  }}
                  onClick={function () {
                    setNoteModalOpen(true);
                  }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <GuesstimationViewContent
        {...props}
        data={data}
        showSkeleton={showSkeleton}
      />

      <NotesModal
        show={noteModalOpen}
        boardId={props.board.id}
        initiatives={initiatives}
        isBoardView={true}
        closeHandler={function () {
          setNoteModalOpen(false);
        }}
      />

      <GuesstimationItemViewModal />

      <CreateModal boardRef={props.boardRef} />
    </>
  );
};

export default GuesstimationView;
