import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGuesstimationItemDetailsResource } from "../interfaces/IGuesstimationItemDetailsResource";
import { IGuesstimationItem } from "../../../../../entities/IGuesstimationItem";
import { IUser } from "../../../../../entities/IUser";

type TInitialState = {
  itemRef?: IGuesstimationItem["ref"];
  detailsResource?: IGuesstimationItemDetailsResource;
  deletedGuesstimationItemRefs: string[];
  addAssigneesDialog?: {
    open: boolean;
    item: IGuesstimationItem;
  };
  removeAssignee?: {
    openConfirmation: boolean;
    item: IGuesstimationItem;
    assignee: IUser;
  };
  deleteItem?: {
    openConfirmation: boolean;
    item: IGuesstimationItem;
  };
};

const initialState: TInitialState = {
  deletedGuesstimationItemRefs: [],
};

const slice = createSlice({
  name: "guesstimationItemView",
  initialState,
  reducers: {
    open: (state, action: PayloadAction<{ ref: string }>) => {
      state.itemRef = action.payload.ref;
    },
    setDetailsResource: (
      state,
      action: PayloadAction<IGuesstimationItemDetailsResource>
    ) => {
      state.detailsResource = action.payload;
    },
    mergeGuesstimationItemProps: (
      state,
      action: PayloadAction<Partial<IGuesstimationItem>>
    ) => {
      if (state.detailsResource?.guesstimationItem) {
        state.detailsResource.guesstimationItem = {
          ...state.detailsResource.guesstimationItem,
          ...action.payload,
        };
      }
    },
    removeAssignee: (state, action: PayloadAction<IUser>) => {
      if (state.detailsResource) {
        state.detailsResource.assignees =
          state.detailsResource.assignees.filter(
            (assignee) => assignee.id !== action.payload.id
          );
      }
    },
    openAddAssigneesDialog: (
      state,
      action: PayloadAction<{
        item: IGuesstimationItem;
      }>
    ) => {
      state.addAssigneesDialog = {
        open: true,
        item: action.payload.item,
      };
    },
    closeAddAssigneesDialog: (state) => {
      state.addAssigneesDialog = undefined;
    },
    openRemoveAssigneeConfirmation: (
      state,
      action: PayloadAction<{
        item: IGuesstimationItem;
        assignee: IUser;
      }>
    ) => {
      state.removeAssignee = {
        openConfirmation: true,
        item: action.payload.item,
        assignee: action.payload.assignee,
      };
    },
    closeRemoveAssigneeConfirmation: (state) => {
      state.removeAssignee = undefined;
    },
    openDeleteItemConfirmation: (
      state,
      action: PayloadAction<{
        item: IGuesstimationItem;
      }>
    ) => {
      state.deleteItem = {
        openConfirmation: true,
        item: action.payload.item,
      };
    },
    closeDeleteItemConfirmation: (state) => {
      state.deleteItem = undefined;
    },
    addGuesstimationItemDeletedRef: (
      state,
      action: PayloadAction<{ guesstimationItemRef: string }>
    ) => {
      if (
        !state.deletedGuesstimationItemRefs.includes(
          action.payload.guesstimationItemRef
        )
      ) {
        state.deletedGuesstimationItemRefs.push(
          action.payload.guesstimationItemRef
        );
      }
    },
    reset: () => initialState,
  },
});

export const guesstimationItemViewReducer = slice.reducer;

export const guesstimationItemViewActions = slice.actions;
