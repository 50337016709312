import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useUpdateGuesstimationItemService } from "../../services/useUpdateGuesstimationItemService";
import EntityTaskTypeUpdateForm from "../../../../../taskType/containers/EntityTaskTypeUpdateForm/EntityTaskTypeUpdateForm";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { TGuesstimationItemUpdatedEvent } from "../types/TGuesstimationItemUpdatedEvent";
import { IGuesstimationItem } from "../../../../../../entities/IGuesstimationItem";
import { IGuesstimationItemDetailsResource } from "../../interfaces/IGuesstimationItemDetailsResource";

type TProps = {
  guesstimationItem: IGuesstimationItem;
  noLabel?: boolean;
};

const TaskTypeForm = ({ guesstimationItem, noLabel }: TProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const { dispatch: dispatchUpdateGuesstimationItemService } =
    useUpdateGuesstimationItemService(guesstimationItem.id);

  const changeHandler = (taskTypeId: number | null) => {
    dispatchUpdateGuesstimationItemService({
      body: {
        taskTypeId: taskTypeId,
      },
    })
      .then((patchResource: IGuesstimationItemDetailsResource) => {
        dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_UPDATED, {
          initGuesstimationItem: guesstimationItem,
          guesstimationItem: patchResource.guesstimationItem,
        } as TGuesstimationItemUpdatedEvent);
      })
      .catch((_: THttpClientError) => {
        dispatch(
          systemNotificationActions.open({
            message:
              "There was a problem with updating the Task Type. Please try again.",
            variant: "error",
          })
        );
      });
  };

  return (
    <EntityTaskTypeUpdateForm
      projectRef={guesstimationItem.projectRef}
      taskTypeId={guesstimationItem.taskTypeId}
      onChange={changeHandler}
      isLoading={!guesstimationItem.id}
      noLabel={noLabel}
    />
  );
};

export default TaskTypeForm;
