import Title from "../../../../common/components/Title/Title";
import GuesstimationView from "../../../boardView/modules/guesstimationView/containers/GuesstimationView/GuesstimationView";
import { TBoardViewProps } from "../../types/TBoardViewProps";
import useKeyboardShortcut from "../../../../common/modules/keyboardShortcut/hooks/useKeyboardShortcut";
import { KeyboardShortcutEnum } from "../../../../common/modules/keyboardShortcut/hooks/enums/KeyboardShortcutEnum";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { guesstimationItemCreateActions } from "../../../guesstimation/modules/create/slices/guesstimationItemCreateSlice";

const GuesstimationBoard = (props: TBoardViewProps) => {
  // @TODO archive board and disabling "Task Default Values" must be considered,
  // @TODO for that we must know what is board type in the menu
  // useRegisterHeader(HeaderRegistryEnum.board, [props.board.ref!], {
  //   boardRef: props.board.ref!,
  //   projectAbbr: props.projectAbbr,
  // });
  const dispatch = useAppDispatch();

  // For now on quick task creation we will launch the task creation dialog, later to be prompt creation
  useKeyboardShortcut({
    key: KeyboardShortcutEnum.t,
    callback: () =>
      dispatch(guesstimationItemCreateActions.openCreationModal()),
  });

  return (
    <>
      <Title subtitle={`${props.board.type} board`}># {props.board.name}</Title>

      <GuesstimationView {...props} />
    </>
  );
};

export default GuesstimationBoard;
