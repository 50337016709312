import { useAppDispatch } from "../../../../../common/hooks/redux";
import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IGuesstimationViewDataRequest } from "../../../interfaces/IGuesstimationViewDataRequest";
import { IGuesstimationViewDataResource } from "../../../interfaces/IGuesstimationViewDataResource";
import { guesstimationViewActions } from "../slices/guesstimationViewSlice";

export const useFetchGuesstimationViewDataService = (props: {
  boardRef: string;
  query: IGuesstimationViewDataRequest;
}) => {
  const dispatch = useAppDispatch();
  const response = useHttpClientDispatchable({
    url: `/guesstimationItem/${props.boardRef}/list`,
    method: RequestMethodEnum.GET,
    query: props.query,
    events: {
      beforeTransform: (data: IGuesstimationViewDataResource) => {
        dispatch(guesstimationViewActions.setData(data));
      },
    },
  });

  return {
    ...response,
    data: response.data as IGuesstimationViewDataResource,
  };
};
