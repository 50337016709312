import { Box, Grid, useTheme } from "@mui/material";
import FileUploadProvider from "../../../../../common/containers/FileUploadProvider/FileUploadProvider";
import { MAIN_CONTENT_PADDING_BOTTOM } from "../../../../../common/containers/Layout/App/Content/Content";
import useUploadAttachmentServiceHelper from "../../../../attachment/hooks/useUploadAttachmentServiceHelper";
import { EntityTypeEnum } from "../../../../../entities/enums/EntityTypeEnum";
import { TGuesstimationItemContentProps } from "./types/TGuesstimationItemContentProps";
import QuickActions from "../../../../../common/modules/taskView/containers/QuickActions/QuickActions";
import AddAttachment from "../../../../../common/modules/taskView/containers/QuickActions/containers/AddAttachment";
import GuesstimationItemViewHeader, {
  GUESSTIMATION_ITEM_VIEW_MAIN_CONTENT_HEADER_HEIGHT,
} from "./Header/GuesstimationItemViewHeader";
import { FormatListBulleted } from "@mui/icons-material";
import Accordion from "../../../../../common/modules/taskView/containers/Sections/Accordion";
import StatusForm from "./Status/StatusForm";
import GuesstimationItemViewMainContent from "./GuesstimationItemViewMainContent/GuesstimationItemViewMainContent";
import GuesstimationItemViewDetails from "./GuesstimationItemViewDetails/GuesstimationItemViewDetails";
import DeleteGuesstimationItem from "./QuickActions/containers/DeleteGuesstimationItem";

const GuesstimationItemViewDesktop = ({
  detailsResource,
  contentHeight,
  isMobileView,
  isPageView,
}: TGuesstimationItemContentProps) => {
  const theme = useTheme();

  const { guesstimationItem } = detailsResource;
  const entityType = EntityTypeEnum.GUESSTIMATION_ITEM;
  const id = guesstimationItem.id;

  const { dispatch: dispatchUploadAttachmentService } =
    useUploadAttachmentServiceHelper(entityType, id);

  const guesstimationItemLabel = "item";

  return (
    <Grid
      container
      height="100%"
      bgcolor={theme.palette.taskView.mainBackground}
      alignItems="flex-start"
      wrap="nowrap"
    >
      <Grid item height="100%" width="calc(100% - 405px)">
        <GuesstimationItemViewHeader
          guesstimationItem={guesstimationItem}
          isMobileView={isMobileView}
          isPageView={isPageView}
        />

        <Grid
          container
          height={`calc(${contentHeight} - ${GUESSTIMATION_ITEM_VIEW_MAIN_CONTENT_HEADER_HEIGHT}px)`}
          sx={{ overflowY: "auto" }}
        >
          <Grid container p="20px 26px" flexDirection="column">
            <GuesstimationItemViewMainContent
              resource={detailsResource}
              isMobileView={isMobileView}
              isPageView={isPageView}
            />
          </Grid>
        </Grid>
      </Grid>

      <FileUploadProvider
        onFileLoad={(file) => dispatchUploadAttachmentService(file)}
        fileDropText={`Drop files here to attach to the ${guesstimationItemLabel}`}
        // @TODO rename
        dropZoneId="taskDropZone"
        // @TODO rename values
        inputConfig={{
          id: "taskAttachment",
          name: "taskAttachment",
        }}
        style={{
          bottom: 0,
          height: contentHeight,
          right: 0,
          width: "405px",
        }}
        fileDropZoneTextBoxStyles={{
          bottom: "150px !important",
          width: "90%",
        }}
        labelStyles={{
          height: "100%",
        }}
        shouldRender={true}
      >
        <Grid
          container
          item
          bgcolor={theme.palette.taskView.secondaryBackground}
          borderLeft="1px solid rgba(0, 0, 0, 0.10)"
          height={contentHeight}
          p="16px 32px"
          pb={isPageView ? `${MAIN_CONTENT_PADDING_BOTTOM}px` : undefined}
          position="relative"
          width="405px"
          flexDirection="column"
          flexWrap="nowrap"
          gap="20px"
        >
          <Grid container>
            <StatusForm guesstimationItem={guesstimationItem} />
          </Grid>

          <Box sx={{ overflowY: "auto" }}>
            <Accordion title="Details" icon={<FormatListBulleted />} mb>
              <GuesstimationItemViewDetails resource={detailsResource} />
            </Accordion>
          </Box>

          <QuickActions
            isMobileView={isMobileView}
            // @TODO not sure if this is needed
            // sx={{
            //   marginTop: !havingCustomFields
            //     ? "0 !important"
            //     : "auto !important",
            // }}
          >
            <AddAttachment
              isMobileView={isMobileView}
              entityType={entityType}
              entityId={id}
            />
            <DeleteGuesstimationItem guesstimationItem={guesstimationItem} />
          </QuickActions>
        </Grid>
      </FileUploadProvider>
    </Grid>
  );
};

export default GuesstimationItemViewDesktop;
