import Title from "../../../../common/components/Title/Title";
import { TBacklogBoardProps } from "./types/TBacklogBoardProps";
import BacklogView from "../../../boardView/modules/backlogView/containers/BacklogView";
import useKeyboardShortcut from "../../../../common/modules/keyboardShortcut/hooks/useKeyboardShortcut";
import { KeyboardShortcutEnum } from "../../../../common/modules/keyboardShortcut/hooks/enums/KeyboardShortcutEnum";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { backlogItemCreateActions } from "../../../backlog/modules/create/slices/backlogItemCreateSlice";
import useRegisterHeader from "../../../../common/modules/registry/hooks/useRegisterHeader";
import { HeaderRegistryEnum } from "../../../../common/modules/registry/enums/HeaderRegistryEnum";
import { useIsFeatureAllowed } from "../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";

const BacklogBoard = (props: TBacklogBoardProps) => {
  const dispatch = useAppDispatch();
  const isBacklogAllowed = useIsFeatureAllowed(FeatureEnum.BACKLOG_ICEBOX);

  useKeyboardShortcut({
    key: KeyboardShortcutEnum.t,
    callback: () =>
      isBacklogAllowed &&
      dispatch(backlogItemCreateActions.openCreationModal()),
  });

  useRegisterHeader(HeaderRegistryEnum.board, [props.board.ref!], {
    boardRef: props.board.ref!,
    projectAbbr: props.projectAbbr,
  });

  return (
    <>
      <Title subtitle={`${props.board.type} board`}># {props.board.name}</Title>

      <BacklogView {...props} />
    </>
  );
};

export default BacklogBoard;
