import {
  combineReducers,
  configureStore,
  createAction,
  Reducer,
  StateFromReducersMapObject,
} from "@reduxjs/toolkit";
import { headerReducer } from "./headerSlice";
import { navigationReducer } from "./navigationSlice";
import { projectReducer } from "../../modules/project/slices/projectSlice";
import { boardReducer } from "../../modules/board/slices/boardSlice";
import { monitorReducer } from "../../modules/monitor/slices/monitorSlice";
import { sprintReducer } from "../../modules/sprint/slices/sprintSlice";
import { taskReducer } from "../../modules/task/slices/taskSlice";
import { listingReducer } from "../modules/listing/slices/listingSlice";
import { holisticViewReducer } from "../../modules/boardView/modules/holisticView/slices/holisticViewSlice";
import { customizationReducer } from "./customizationSlice";
import { offtimeReducer } from "../../modules/offtime/slices/offtimeSlice";
import { systemNotificationReducer } from "../modules/systemNotification/slices/systemNotificationSlice";
import { integrationReducer } from "../../modules/integrations/slices/integrationSlice";
import { boardIntegrationReducer } from "../../modules/board/slices/boardIntegrationSlice";
import { priorityViewReducer } from "../../modules/view/priorityView/slices/priorityViewSlice";
import { themeReducer } from "../modules/theme/slices/themeSlice";
import { holisticViewTaskReducer } from "../../modules/boardView/modules/holisticView/slices/holisticViewTaskSlice";
import { boardViewReducer } from "../../modules/boardView/slices/boardViewSlice";
import { accessControlReducer } from "../modules/accessControl/slices/accessControlSlice";
import { commentReducer } from "../../modules/comment/slices/commentSlice";
import { kanbanViewReducer } from "../../modules/boardView/modules/kanbanView/slices/kanbanViewSlice";
import { listViewReducer } from "../../modules/boardView/modules/listView/slices/listViewSlice";
import { teamReducer } from "../../modules/team/slices/teamSlice";
import { userReducer } from "../../modules/user/slices/userSlice";
import { landingPageReducer } from "../modules/landingPage/slices/landingPageSlice";
import { orgReducer } from "../../modules/org/slices/orgSlice";
import { customFieldReducer } from "../../modules/customField/slices/customFieldSlice";
import { attachmentReducer } from "../../modules/attachment/slices/attachmentSlice";
import { taskTypeReducer } from "../../modules/taskType/slices/taskTypeSlice";
import { taskPriorityReducer } from "../../modules/taskPriority/slices/taskPrioritySlice";
import { linkListReducer } from "../../modules/linkList/slices/linkListSlice";
import { boardQueryReducer } from "../../modules/boardView/slices/boardQuerySlice";
import { taskViewReducer } from "../../modules/task/modules/view/slices/viewSlice";
import { statusFlowReducer } from "../../modules/statusFlow/slices/statusFlowSlice";
import { aiSentinelReducer } from "../../modules/aiSentinel/slices/aiSentinelSlice";
import { exploreAndShareReducer } from "../modules/exploreAndShare/slices/exploreAndShareSlice";
import { kanbanViewTaskReducer } from "../../modules/boardView/modules/kanbanView/slices/kanbanViewTaskSlice";
import { linkReducer } from "../../modules/link/slices/linkSlice";
import { backlogItemViewReducer } from "../../modules/backlog/modules/view/slices/viewSlice";
import { backlogItemCreateReducer } from "../../modules/backlog/modules/create/slices/backlogItemCreateSlice";
import { backlogBoardQueryReducer } from "../../modules/boardView/modules/backlogView/slices/backlogBoardQuerySlice";
import { stripeReducer } from "../../modules/stripe/slices/stripeSlice";
import { objectiveReducer } from "../../modules/objective/slices/objectiveSlice";
import { initiativeReducer } from "../../modules/objective/slices/initiativeSlice";
import { systemReducer } from "./systemSlice";
import { networkingReducer } from "../../modules/networking/slices/networkingSlice";
import { guesstimationViewReducer } from "../../modules/boardView/modules/guesstimationView/slices/guesstimationViewSlice";
import { guesstimationItemCreateReducer } from "../../modules/guesstimation/modules/create/slices/guesstimationItemCreateSlice";
import { guesstimationViewItemReducer } from "../../modules/boardView/modules/guesstimationView/slices/guesstimationViewItemSlice";
import { guesstimationItemViewReducer } from "../../modules/guesstimation/modules/view/slices/viewSlice";

const reducers = {
  header: headerReducer,
  navigation: navigationReducer,
  project: projectReducer,
  board: boardReducer,
  monitor: monitorReducer,
  sprint: sprintReducer,
  task: taskReducer,
  link: linkReducer,
  attachment: attachmentReducer,
  listing: listingReducer,
  holisticView: holisticViewReducer,
  holisticViewTask: holisticViewTaskReducer,
  guesstimationView: guesstimationViewReducer,
  guesstimationViewItem: guesstimationViewItemReducer,
  customs: customizationReducer,
  offtime: offtimeReducer,
  systemNotification: systemNotificationReducer,
  integration: integrationReducer,
  boardIntegration: boardIntegrationReducer,
  priorityView: priorityViewReducer,
  theme: themeReducer,
  boardView: boardViewReducer,
  boardQuery: boardQueryReducer,
  backlogBoardQuery: backlogBoardQueryReducer,
  accessControl: accessControlReducer,
  comment: commentReducer,
  kanbanView: kanbanViewReducer,
  kanbanViewTask: kanbanViewTaskReducer,
  listView: listViewReducer,
  team: teamReducer,
  user: userReducer,
  landingPage: landingPageReducer,
  org: orgReducer,
  customField: customFieldReducer,
  taskType: taskTypeReducer,
  taskPriority: taskPriorityReducer,
  statusFlow: statusFlowReducer,
  linkList: linkListReducer,
  taskView: taskViewReducer,
  aiSentinel: aiSentinelReducer,
  exploreAndShare: exploreAndShareReducer,
  backlogItemView: backlogItemViewReducer,
  backlogItemCreate: backlogItemCreateReducer,
  guesstimationItemCreate: guesstimationItemCreateReducer,
  guesstimationItemView: guesstimationItemViewReducer,
  stripe: stripeReducer,
  objective: objectiveReducer,
  initiative: initiativeReducer,
  system: systemReducer,
  networking: networkingReducer,
};

// Infer RootState from the reducers object
export type RootState = StateFromReducersMapObject<typeof reducers>;

// All the reducers are combined into one
const combinedReducer: Reducer<RootState> = combineReducers(reducers);

// Root reducer is used to reset the global store
const rootReducer: Reducer<RootState> = (
  state,
  action: {
    type: "resetStore";
  }
) => {
  if (action.type === "resetStore") {
    // Returning undefined forces the combined reducer to return the initial state
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export const resetStore = createAction("resetStore");
