import GuesstimationItemViewMobile from "./GuesstimationItemViewMobile";
import GuesstimationItemViewDesktop from "./GuesstimationItemViewDesktop";
import { TGuesstimationItemContentProps } from "./types/TGuesstimationItemContentProps";
import AddAssigneesDialog from "./AddAssignees/AddAssigneesDialog";
import RemoveAssigneeConfirmation from "./RemoveAssignee/RemoveAssigneeConfirmation";
import DeleteGuesstimationItemConfirmation from "./DeleteGuesstimationItem/DeleteGuesstimationItemConfirmation";

const GuesstimationItemContent = (props: TGuesstimationItemContentProps) => {
  return (
    <>
      {props.isMobileView ? (
        <GuesstimationItemViewMobile {...props} />
      ) : (
        <GuesstimationItemViewDesktop {...props} />
      )}

      <AddAssigneesDialog />
      <RemoveAssigneeConfirmation />
      <DeleteGuesstimationItemConfirmation isOnPage={!!props.isPageView} />
    </>
  );
};

export default GuesstimationItemContent;
