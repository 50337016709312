import Title from "../../../../common/components/Title/Title";
import GuesstimationView from "../../../boardView/modules/guesstimationView/containers/GuesstimationView/GuesstimationView";
import { TBoardViewProps } from "../../types/TBoardViewProps";
import useKeyboardShortcut from "../../../../common/modules/keyboardShortcut/hooks/useKeyboardShortcut";
import { KeyboardShortcutEnum } from "../../../../common/modules/keyboardShortcut/hooks/enums/KeyboardShortcutEnum";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { guesstimationItemCreateActions } from "../../../guesstimation/modules/create/slices/guesstimationItemCreateSlice";
import { useEffect } from "react";
import { userActions } from "../../../user/slices/userSlice";
import { boardQueryActions } from "../../../boardView/slices/boardQuerySlice";
import { guesstimationViewActions } from "../../../boardView/modules/guesstimationView/slices/guesstimationViewSlice";
import useRegisterHeader from "../../../../common/modules/registry/hooks/useRegisterHeader";
import { HeaderRegistryEnum } from "../../../../common/modules/registry/enums/HeaderRegistryEnum";
import { BoardTypeEnum } from "../../../../entities/columns/board/BoardTypeEnum";
import { THeaderControlProps as TBoardHeaderControlProps } from "../HeaderControl/types/THeaderControlProps";
import { useIsFeatureAllowed } from "../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";

const GuesstimationBoard = (props: TBoardViewProps) => {
  useRegisterHeader(HeaderRegistryEnum.board, [props.board.ref!], {
    boardRef: props.board.ref!,
    projectAbbr: props.projectAbbr,
    boardType: BoardTypeEnum.GUESSTIMATION,
  } as TBoardHeaderControlProps);
  const dispatch = useAppDispatch();
  const isGuesstimationAllowed = useIsFeatureAllowed(
    FeatureEnum.GUESSTIMATION_BOARD
  );

  // For now on quick task creation we will launch the task creation dialog, later to be prompt creation
  useKeyboardShortcut({
    key: KeyboardShortcutEnum.t,
    callback: () =>
      isGuesstimationAllowed &&
      dispatch(guesstimationItemCreateActions.openCreationModal()),
  });

  useEffect(() => {
    return () => {
      dispatch(guesstimationViewActions.reset());
      // Reset user list when leaving the board
      dispatch(userActions.reset());
      dispatch(boardQueryActions.reset());
    };
  }, [props.boardRef]);

  return (
    <>
      <Title subtitle={`${props.board.type} board`}># {props.board.name}</Title>

      <GuesstimationView {...props} />
    </>
  );
};

export default GuesstimationBoard;
